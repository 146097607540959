const generateListItem = data => {
  return {
    "@type": "ListItem",
    position: data.position,
    name: data.name,
    item: data.url,
  }
}

export default generateListItem
