import React from "react"
import PropTypes from "prop-types"
import InputSwitch from "@src/components/input-switch"
import { SYSTEM_NAMES, SYSTEM_UNITS } from "@src/utils/distances"

const InputUnitsSwitch = ({ labelType, value, onChange, ...props }) => {
  return (
    <InputSwitch
      value={value}
      onChange={onChange}
      items={[
        {
          text: labelType === "long" ? SYSTEM_NAMES.metric : SYSTEM_UNITS.meter,
          value: SYSTEM_NAMES.metric,
        },
        {
          text:
            labelType === "long" ? SYSTEM_NAMES.imperial : SYSTEM_UNITS.foot,
          value: SYSTEM_NAMES.imperial,
        },
      ]}
      {...props}
    />
  )
}
InputUnitsSwitch.propTypes = {
  value: PropTypes.oneOf([SYSTEM_NAMES.imperial, SYSTEM_NAMES.metric]),
  onChange: PropTypes.func,
  labelType: PropTypes.oneOf(["long", "short"]),
}
InputUnitsSwitch.defaultProps = {
  labelType: "short",
}
export default InputUnitsSwitch
