import React, { useState } from "react"
import styled from "styled-components"
import Image from "@src/components/core-image"
import ModalContainer from "@src/components/container-modal"
import QuestionForm from "@src/components/form-question"
import PropTypes from "prop-types"

const TravelAdviceDiv = styled.div`
  background-color: #FBF8F5;
  background: url(/images/beige-background.jpg)  #FBF8F5 50% / cover no-repeat;
  background-blend-mode: multiply;
  display: flex;

  & > div {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    & .lazyload-wrapper {
      & img {
        border-radius: 8px;
      }
    }
    & .quote {
      margin: 24px;
      & h3 {
        color: #1F1F23;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 28px;
        line-height: 64px;
        /* Headings/Heading2.5 */
        font-family: system-ui;
        font-style: normal;
        font-weight: 900;
        line-height: 50px; /* 131.579% */
        letter-spacing: 0.5px;
        margin-bottom: 24px;
      }
      & p {
        line-height: 30px;
        color: #315254;
        font-family: system-ui;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
        letter-spacing: 0.5px;
      }
    }
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    & > div {
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      gap: 100px;
      & .quote {
        padding-left: 115px;
        margin: 20px;
        & h3 {
          font-size: 38px;
          margin-bottom: 45px;
        }
      }
    }
  }
`
const ConnectModal = styled(ModalContainer)`
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
` 

const ConnectButton = styled.button`
  width: 100%;
  cursor: pointer;
  color: #26383B;
  font-family: system-ui;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.09px;
  display: inline-flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: none;
  border-radius: 8px;
  background: #FEC51B;
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: auto;
  }
`

const TravelAdvice = ({title}) => {
  const [isQuestionOpen, setIsQuestionOpen] = useState(false)

  const ConnectToAdvisorModal = (
    <ConnectModal
      id="ask-question-modal"
      isOpen={isQuestionOpen}
      onClose={() => setIsQuestionOpen(false)}
    >
      <p>
        Chat with us on our{" "}
        <a
          href="/contact/"
          target="_blank"
          aria-label="Contact page, opens a new tab"
        >
          contact page
        </a>{" "}
        or fill out the form below!
      </p>
      <QuestionForm
        name="trail-question-zoho"
        onClose={() => setIsQuestionOpen(false)}
        pageName={title}
      />
    </ConnectModal>
  )

  return (
    <TravelAdviceDiv>
      <div>        
        <div className="quote">
          <p>WE ARE HERE TO HELP</p>
          <h3>
            Let someone else do the hard 
            work, book a custom tour in
            over 85 countries.
          </h3>
          <ConnectButton onClick={() => setIsQuestionOpen(true)}>Connect with a travel advisor</ConnectButton>
          {ConnectToAdvisorModal}
        </div>
        <Image
          placeholder
          src="/images/summer-adventure.png"
          alt="10adventures-quote"
          width={550}
          height={550}
        />
      </div>
    </TravelAdviceDiv>
  )
}

TravelAdvice.propTypes = {
  title: PropTypes.string,
}

export default TravelAdvice