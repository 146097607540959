import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Button from "@src/components/core-button"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import {
  ImagePropTypes,
  ImageCreditsPropTypes,
} from "@src/components/core-image"
import CarouselContainer from "@src/components/container-carousel"
import SiteSearch from "@src/components/site-search-routesV2"

const HeaderContentStyles = css`
  width: 100%;
  padding: 0 16px;

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: auto;
    grid-row: 5 / 6;
    grid-column: 1 / 3;
    padding: 0 30px;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    padding: 20px 80px 10px;
  }
`

const HeaderContent = styled.div`
  ${HeaderContentStyles}
`

const HeaderTitle = styled.h1`
  color: ${props => props.theme.white};
  font-weight: 900;
  font-size: 46px;
  letter-spacing: 0.5px;

  @media only screen and (${props => props.theme.screen.medium.max}) {
    font-size: 32px;
  }
`

const HeaderImageContainer = styled.div`
  height: 500px;
  width: 100%;
  position: relative;
  overflow: hidden;

  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 537px;
    grid-row: 2 / 4;
    grid-column: 1 / 2;
  }
`
const HeaderImageWrapper = styled(ImageWrapper)`
  width: 100%;
  height: 100%;
`

const HeaderStyled = styled.section`
  display: grid;
  & ${HeaderContent} {
    box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.08);
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    & ${HeaderContent} {
      padding: 20px 30px;
      box-shadow: 0 4px 12px 4px rgba(0, 0, 0, 0.08);
    }
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    & ${HeaderContent} {
      padding: 20px 80px;
    }
  }
`

const HeaderImage = styled(Image)`
  filter: brightness(0.75);
`

const HeaderTitleContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30%;
  width: 100%;
`

const Search = styled(SiteSearch)`
  background-color: transparent;
  margin-top: 30px;
  padding: 0;
  z-index: ${props => props.theme.layers.siteHeader - 40};
  width: 60%;

  & .results {
    position: absolute;
    top: 100%;
    left: 20px;
    right: 20px;
    border-radius: 0 4px 4px 4px;
    width: 90%;
    max-height: 250px;
    z-index: ${props => props.theme.layers.siteHeader};
  }

  @media only screen and (${props => props.theme.screen.small.max}) {
    width: 80%;
  }
`

const StyledCarousel = styled(CarouselContainer)`
  display: flex;
  margin: auto 0;
  padding: 0 24px;
  width: 100%;
  align-items: center;
  justify-content: center;

  & > button.prev {
    left: 2;
  }
  & > button.next {
    right: 2;
  }
`

const ActivityBadgeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`

const ActivityBadge = styled(Button)`
  padding: 8px 10px;
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 40px;
  &:hover {
    cursor: pointer;
  }
`

const ActivityBadgeActive = styled(Button)`
  padding: 8px 10px;
  background: #ffffff;
  color: #26383b;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  margin-top: 40px;
`

const RegionHeader = ({
  id,
  title,
  image,
  imageOverlayText,
  variant,
  activityTypeNameSport,
  regionActivityNameSport,
}) => {
  let imageWrapperType = HeaderImageWrapper
  const imageWrapperProps = imageOverlayText
    ? {
        overlayText: imageOverlayText.reduce(
          (result, { key, value }) => ({ ...result, [key]: value }),
          {}
        ),
      }
    : undefined

  return (
    <>
      <div id={id}>
        <HeaderStyled>
          <HeaderImageContainer>
            <HeaderImage
              lazyload={false}
              wrapper={imageWrapperType}
              wrapperProps={imageWrapperProps}
              {...image}
            />
            {variant !== "overlay" && (
              <HeaderTitleContents>
                <HeaderTitle>{title}</HeaderTitle>
                <Search />
                <StyledCarousel
                  mobileSizeOnly
                  buttonType={"none"}
                  active
                  variant="routes"
                >
                  <ActivityBadgeWrapper>
                    {activityTypeNameSport.map((activity, index) => {
                      return activity.name === regionActivityNameSport ? (
                        <ActivityBadgeActive key={index}>
                          {activity.name}
                        </ActivityBadgeActive>
                      ) : (
                        <ActivityBadge key={index} to={activity.uri}>
                          {activity.name}
                        </ActivityBadge>
                      )
                    })}
                  </ActivityBadgeWrapper>
                </StyledCarousel>
              </HeaderTitleContents>
            )}
          </HeaderImageContainer>
        </HeaderStyled>
      </div>
    </>
  )
}
RegionHeader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  imageOverlayText: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  imageDummy: PropTypes.string,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      credits: PropTypes.shape({ ...ImageCreditsPropTypes }),
    })
  ),
  tripPlanners: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.object,
      link: PropTypes.string,
      primary: PropTypes.bool,
    })
  ),
  ultimateGuide: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string,
    images: PropTypes.shape({
      headerTile: PropTypes.shape({ ...ImagePropTypes }),
    }),
  }),
  variant: PropTypes.oneOf(["general", "general2", "overlay"]),
  uploadPhotosButton: PropTypes.bool,
  overlay: PropTypes.node,
  galleryOpen: PropTypes.bool,
  onGalleryClose: PropTypes.func,
  regionDetails: PropTypes.shape({
    regionTypeName: PropTypes.string,
    parent: PropTypes.string,
    grandparent: PropTypes.string,
  }),
  pagePath: PropTypes.string,
  activityTypeNameSport: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, uri: PropTypes.string })
  ),
  regionActivityNameSport: PropTypes.string,
}
export default RegionHeader
