import { useRouteRegions } from "@src/hooks//useRouteRegions"
import { getAncestorList } from "@src/utils/trees"
export const useRouteBreadcrumbs = regionId => {
  const routeRegionsList = useRouteRegions()
  const ancestorList = getAncestorList(regionId, routeRegionsList)
  const headerBreadcrumbs = []

  if (ancestorList && ancestorList.length) {
    ancestorList.forEach(ancestor => {
      const ancestorRegion = routeRegionsList.find(item => item.id === ancestor)
      const { name: text, slug } = ancestorRegion
      headerBreadcrumbs.push({
        text,
        slug,
      })
    })
  }

  return headerBreadcrumbs
}
