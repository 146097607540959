import React, { useState, useEffect, useCallback } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import RouteContent from "@src/services/content/routes"
import { PageSeoPropTypes } from "@src/components/page-helmet"
import { RouteGuideDetailTilePropTypes } from "@src/components/content-route-guide-detail-tile"
import { ImagePropTypes } from "@src/components/core-image"
import { ROUTE_SORTING_OPTIONS } from "@src/utils/constants/route-list-options"
import { useRouteBreadcrumbs } from "@src/hooks/useRouteBreadcrumbs"
import { useRouteRegionsTree } from "@src/hooks/useRouteRegions"
import { useRouteActivityTypes } from "@src/hooks/useRouteActivityTypes"
import RouteLayout from "@src/layouts/routes"
import { debounce } from "debounce"
import { scroller } from "react-scroll"

const PageTemplate = ({
  data,
  pageContext: {
    basePath,
    pageNumber,
    pageCount,
    postsPerPage,
    postsTotal,
    ...pageContext
  },
}) => {
  const {
    region: {
      regionId,
      regionName,
      regionSlug,
      regionTypeName,
      regionContinent,
      regionGps,
      regionImages: { header, regionMap },
      seo: pageSeo,
    },
  } = data

  const {
    ancestorRegions: ancestors,
    routeGuides: { nodes: dataRouteGuides },
    activityRegionDetails: {
      regionActivityTitle,
      regionActivityDescription,
      regionActivitySeo: { seoTitle, seoDescription },
      regionActivity: { activityId, activityName, activityUri },
    },
  } = pageContext.data

  const activityTypeDetails = pageContext.data.activityTypeDetails?.nodes
  const regionActivityNameSport =
    pageContext.data.activityRegionDetails.regionActivity.activityNameSport

  const activityTypeNameSport = activityTypeDetails.map(activityType => {
    return {
      name: activityType.activityType.nameSport,
      uri: activityType.uri,
    }
  })
  const activityTypeIds = activityTypeDetails.map(activityType => {
    return activityType.activityType.id
  })

  const gtmPageDetails = {}

  const [routesLoading, setRoutesLoading] = useState(false)
  const [sorting, setSorting] = useState(ROUTE_SORTING_OPTIONS[0].value)
  const [routeGuides, setRouteGuides] = useState(dataRouteGuides)
  const [filters, setFilters] = useState({
    regions: [regionId],
    activities: [activityId],
    sortBy: sorting,
  })

  const [page, setPage] = useState({
    pageNumber: pageNumber,
    pageCount: pageCount,
    postsPerPage: postsPerPage,
    postsTotal: postsTotal,
    countOffset: (pageNumber - 1) * postsPerPage,
  })

  const defaultFilters = {
    regions: [regionId],
    activities: [activityId],
    sortBy: filters.sortBy,
  }

  const breadcrumbRegions = useRouteBreadcrumbs(regionId)
  const routeRegionsTree = useRouteRegionsTree()
  const routeActivityTypes = useRouteActivityTypes()

  const handleScroll = () => {
    scroller.scrollTo("route-container", { offset: -125 })
  }

  const refreshRouteList = useCallback(
    page => {
      RouteContent.getFilteredList(
        {
          sortBy: filters.sortBy,
          activities: filters.activities,
          time: filters.time,
          distance: filters.distance,
          difficulty: filters.difficulty,
          rating: filters.rating,
          regions: filters.regions,
        },
        {
          page: page || 1,
        }
      ).then(result => {
        if (!result || !result.items) {
          return
        }

        if (result.items.length === 0) {
          handleScroll()
        }

        setRouteGuides(result.items)
        setPage({
          pageNumber: result.page,
          pageCount: result.pageCount,
          postsPerPage: result.perPage,
          postsTotal: result.total,
          countOffset: result.firstIndex,
        })
      })
      setRoutesLoading(false)
    },
    [filters]
  )

  useEffect(() => {
    refreshRouteList()
  }, [filters, refreshRouteList])

  // filter updaters
  const resetFilters = () => {
    const newFilters = { ...defaultFilters }
    setFilters(newFilters)
    handleScroll()
  }

  const updateFilters = (update, reset) => {
    const newFilters = reset ? { ...update } : { ...filters, ...update }
    setFilters(newFilters)
    if (handleFiltersChange) {
      handleFiltersChange(newFilters)
    }
  }

  const removeFilter = key => {
    const filterCopy = { ...filters }
    delete filterCopy[key]
    updateFilters(filterCopy, true)
  }

  const loadRoutes = filters => {
    const params = {}
    if (filters.sortBy) {
      params.sort = filters.sortBy
    }
    if (filters.activities) {
      params.activities = filters.activities
    }
    if (filters.regions && filters.regions.length) {
      params.regions = filters.regions
    }
    if (filters.time && filters.time.length) {
      params.time = filters.time
    }
    if (filters.distance && filters.distance.length) {
      params.distance = filters.distance
    }
    if (filters.rating && filters.rating.length) {
      params.rating = filters.rating
    }
    if (filters.difficulty && filters.difficulty.length) {
      params.difficulty = filters.difficulty
    }
  }

  const loadRoutesDebounce = debounce(
    (filters, page = 1) => loadRoutes(filters, page),
    250
  )

  const handleFiltersChange = filters => {
    setRoutesLoading(true)
    loadRoutesDebounce(filters)
  }

  // INPUT HANDLERS
  const handleSortingChange = value => {
    setSorting(value)
    updateFilters({ sortBy: value })
    handleScroll()
  }
  const handlePageChange = pageNumber => {
    setRoutesLoading(true)
    loadRoutesDebounce(filters, pageNumber)
    refreshRouteList(pageNumber)
    handleScroll()
  }

  const handleRegionsChange = value => {
    if (!value || !value.length) {
      removeFilter("regions")
      return
    }
    updateFilters({ regions: value })
  }
  const handleActivitiesChange = value => {
    if (!value || !value.length) {
      removeFilter("activities")
      return
    }
    updateFilters({ activities: value })
  }
  const handleTimeChange = value => {
    if (!value || !value.length) {
      removeFilter("time")
      return
    }
    updateFilters({
      time: [value[0], value[1]],
    })
  }
  const handleDistanceChange = value => {
    if (!value || !value.length) {
      removeFilter("distance")
      return
    }
    updateFilters({
      distance: [value[0], value[1]],
    })
  }
  const handleDifficultyChange = value => {
    if (!value || !value.length) {
      removeFilter("difficulty")
      return
    }
    updateFilters({
      difficulty: [value[0], value[1]],
    })
  }
  const handleRatingChange = value => {
    if (!value || !value.length) {
      removeFilter("rating")
      return
    }
    updateFilters({
      rating: [value[0], value[1]],
    })
  }

  const regionDetails = { regionTypeName, parent: regionContinent }

  const breadcrumbs = [
    {
      text: activityName,
      link: `${activityUri}`,
    },
  ]
  if (breadcrumbRegions && breadcrumbRegions.length) {
    breadcrumbRegions.forEach(region =>
      breadcrumbs.push({
        text: region.text,
        link: `${activityUri}${region.slug}/`,
      })
    )
  }
  if (ancestors && ancestors.length) {
    gtmPageDetails["page-country"] = ancestors[0].name
    regionDetails.parent = ancestors[ancestors.length - 1].name
    if (ancestors.length > 1) {
      regionDetails.grandparent = ancestors[ancestors.length - 2].name
    }
  }

  GTM.dataLayerPushPageInfo({
    template: "activity-region-list",
    ...gtmPageDetails,
  })

  return (
    <>
      <RouteLayout
        regionId={regionId}
        regionName={regionName}
        regionSlug={regionSlug}
        regionTypeName={regionTypeName}
        regionContinent={regionContinent}
        header={header}
        regionMap={regionMap}
        regionGps={regionGps}
        pageSeo={pageSeo}
        regionActivityTitle={regionActivityTitle}
        regionActivityDescription={regionActivityDescription}
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        activityName={activityName}
        activityUri={activityUri}
        activityTypeNameSport={activityTypeNameSport}
        routeGuides={{ nodes: routeGuides }}
        regionActivityNameSport={regionActivityNameSport}
        activityTypeIds={activityTypeIds}
        basePath={basePath}
        page={page}
        handleSortingChange={handleSortingChange}
        routeRegionsTree={routeRegionsTree}
        routeActivityTypes={routeActivityTypes}
        filters={filters}
        handleRegionsChange={handleRegionsChange}
        handleActivitiesChange={handleActivitiesChange}
        handleTimeChange={handleTimeChange}
        handleDistanceChange={handleDistanceChange}
        handleDifficultyChange={handleDifficultyChange}
        handleRatingChange={handleRatingChange}
        resetFilters={resetFilters}
        handlePageChange={handlePageChange}
        countOffset={page.countOffset}
        postsTotal={page.postsTotal}
        postsPerPage={page.postsPerPage}
        pageNumber={page.pageNumber}
        pageCount={page.pageCount}
        sorting={filters.sortBy}
        routesLoading={routesLoading}
        handleScroll={handleScroll}
      />
    </>
  )
}
PageTemplate.propTypes = {
  data: PropTypes.shape({
    region: PropTypes.shape({
      regionId: PropTypes.number,
      regionName: PropTypes.string,
      regionSlug: PropTypes.string,
      regionTypeName: PropTypes.string,
      regionContinent: PropTypes.string,
      regionImages: PropTypes.object,
      regionGps: PropTypes.shape({
        lat: PropTypes.number,
        lon: PropTypes.number,
      }),
      seo: PropTypes.shape({ ...PageSeoPropTypes }),
    }),
    ultimateGuide: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.string,
      images: PropTypes.shape({
        headerTile: PropTypes.shape({ ...ImagePropTypes }),
      }),
    }),
  }),
  pageContext: PropTypes.shape({
    basePath: PropTypes.string,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
    postsPerPage: PropTypes.number,
    postsTotal: PropTypes.number,
    data: PropTypes.shape({
      ancestorRegions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
      activityRegionDetails: PropTypes.shape({
        regionActivityTitle: PropTypes.string,
        regionActivityDescription: PropTypes.string,
        regionActivitySeo: PropTypes.shape({
          seoTitle: PropTypes.string,
          seoDescription: PropTypes.string,
        }),
        regionActivity: PropTypes.shape({
          activityId: PropTypes.number,
          activityName: PropTypes.string,
          activityNameSport: PropTypes.string,
          activityNamePlural: PropTypes.string,
          activityUri: PropTypes.string,
        }),
      }),
      activityTypeDetails: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            activityType: PropTypes.shape({
              id: PropTypes.number,
            }),
            routeCount: PropTypes.number,
          })
        ),
      }),
      children: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({
            activityTypeDetails: PropTypes.shape({
              nodes: PropTypes.arrayOf(
                PropTypes.shape({
                  activityType: PropTypes.shape({
                    id: PropTypes.number,
                  }),
                })
              ),
            }),
          })
        ),
      }),
      routeGuides: PropTypes.shape({
        nodes: PropTypes.arrayOf(
          PropTypes.shape({ ...RouteGuideDetailTilePropTypes })
        ),
      }),
    }),
  }),
}

export default PageTemplate

export const query = graphql`
  query ($regionId: String!, $ultimateGuidePostId: String) {
    region: wpRegion(id: { eq: $regionId }) {
      regionId: databaseId
      regionName: name
      regionSlug: slug
      regionType
      regionTypeName
      regionGps: gpsPoint {
        lat
        lon
      }
      regionImages: images {
        header {
          ...WpImageSizesQuery
        }
        regionMap {
          ...WpImageSizesQuery
        }
      }
      seo {
        ...WpTermSeoQuery
      }
    }
    ultimateGuide: wpPost(id: { eq: $ultimateGuidePostId }) {
      uri
      title
      images {
        headerTile {
          ...WpImageSizesQuery
        }
      }
    }
  }
`
