import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Link from "@src/components/core-link"

const BreadcrumbContainer = styled.div`
  padding: 10px 0;
  & > * {
    font-family: "system-ui";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #667085; // Refactor with design guide
  }
  & > a {
    text-decoration: none;
    margin-right: 8px;
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
`

const BreadcrumbsV2 = ({ className, breadcrumbItems }) => {
  return (
    <BreadcrumbContainer className={`breadcrumbs ${className}`}>
      {breadcrumbItems && breadcrumbItems.length
        ? breadcrumbItems.map((item, index) => (
            <React.Fragment key={item.text}>
              <Link to={item.link}>{item.text}</Link>
              {index === breadcrumbItems.length - 1 ? null : <span>/</span>}
            </React.Fragment>
          ))
        : null}
    </BreadcrumbContainer>
  )
}
BreadcrumbsV2.propTypes = {
  className: PropTypes.string,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}
export default BreadcrumbsV2
