import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
const Label = styled.label`
  cursor: pointer;
  position: relative;
  display: inline-flex;
  font-weight: 500;
  font-size: ${props => (props.variant === "routes-v2" ? "16px" : "12px")};
  line-height: 15px;
  margin-bottom: 10px;
  ${props =>
    props.variant === "blue" &&
    css`
      color: ${props.theme.darkBlue};
    `}
  @media only screen and (${props => props.theme.screen.small.min}) {
    font-size: 16px;
    line-height: 20px;
  }
`
const StyledInput = styled.input`
  // Can't use ::before and ::after for inputs without -moz-appearance as items should have content.
  // https://stackoverflow.com/questions/35378360/before-after-pseudo-elements-not-showing-firefox
  -moz-appearance: initial;
  position: relative;
  cursor: pointer;
  width: 8px;
  height: 8px;
  margin: 3px;
  display: inline-block;

  &::before,
  &::after {
    content: "";
    box-sizing: border-box;
    display: inline-block;
    position: absolute;
    z-index: 1;
  }
  &::before {
    top: -3px;
    left: -3px;
    width: 14px;
    height: 14px;

    background-color: ${props => props.theme.bg1};
    border: 1px solid ${props => props.theme.lightGray};
    border-radius: 50%;
  }
  &::after {
    left: 1px;
    top: 1px;
    width: 6px;
    height: 6px;
    background-color: ${props =>
      props.variant === "blue"
        ? props.theme.darkBlue
        : props.variant === "green2"
        ? props.theme.green2
        : props.theme.primary};
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.1s ease-in;
  }

  &:focus::before {
    outline: ${props => props.theme.focusOutline};
    outline: -webkit-focus-ring-color auto 1px;
  }
  &:checked::before {
    border-color: ${props =>
      props.variant === "blue"
        ? props.theme.darkBlue
        : props.variant === "green2"
        ? props.theme.green2
        : props.theme.primary};
  }
  &:checked::after {
    transform: scale(1);
  }

  label > & {
    margin-right: 9px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 14px;
    height: 14px;
    &::before {
      width: 20px;
      height: 20px;
    }
    &::after {
      left: 2px;
      top: 2px;
      width: 10px;
      height: 10px;
    }
    label > & {
      margin-right: 13px;
    }
  }
`
const InputRadio = ({
  id,
  name,
  value,
  checked,
  label,
  onChange,
  variant,
  ...props
}) => {
  const radio = (
    <StyledInput
      type="radio"
      id={id}
      name={name}
      value={value}
      checked={checked}
      onChange={onChange}
      variant={variant}
      {...props}
    />
  )
  return label ? (
    <Label htmlFor={id} variant={variant}>
      {radio}
      <span>{label}</span>
    </Label>
  ) : (
    radio
  )
}
InputRadio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(["blue", "yellow", "green2", "routes-v2"]),
}
export default InputRadio
