import generateListItem from "./generateListItem"
/*
BreadcrumbList:
  - Google: https://developers.google.com/search/docs/advanced/structured-data/breadcrumb
  - Schema.org: https://schema.org/BreadcrumbList
 */
const generateBreadcrumbList = breadcrumbs => {
  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbs.map(breadcrumbData =>
      generateListItem(breadcrumbData)
    ),
  }
}

export default generateBreadcrumbList
