export const MAP_ATTRIBUTION_DATA = [
  {
    name: "Map Data",
    uri: "https://www.openstreetmap.org/",
    text: "OpenStreetMap",
    copyright: "©",
  },
  {
    name: "Tiles",
    uri: "https://www.cyclosm.org/",
    text: "CyclOSM",
    copyright: "©",
  },
]
