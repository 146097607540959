import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import InputRadios from "@src/components/input-radios"

const StyledInputRadios = styled(InputRadios)`
  display: flex;

  & > div {
    & > label {
      margin-bottom: 0;
      position: relative;

      & > input {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0;

        &::before,
        &::after {
          border-radius: 0;
          width: 100%;
          height: 100%;
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
        }

        &::before {
          border-color: ${props =>
            props.variant === "green2"
              ? props.theme.white
              : props.theme.primary};
        }

        &:checked {
          z-index: 2;
          border-radius: 50%;

          &::before,
          &::after {
            border-radius: 50%;
          }

          & + span {
            z-index: 3;
          }
        }
      }

      & > span {
        min-width: 26px;
        padding: 2px 4px;
        color: #272d37;
        font-feature-settings: "clig" off, "liga" off;
        font-family: system-ui;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        display: inline-block;
        position: relative;
        z-index: 1;
        text-align: center;
      }
    }

    &:first-child {
      & > label {
        & > input {
          &,
          &::before,
          &::after {
            ${props =>
              props.variant != "green2" &&
              css`
                border-radius: 4px 0 0 4px;
              `}
          }
        }
      }
    }

    &:last-child {
      & > label {
        & > input {
          &,
          &::before,
          &::after {
            ${props =>
              props.variant != "green2" &&
              css`
                border-radius: 0 4px 4px 0;
              `}
          }
        }
      }
    }
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    & > div {
      & > label {
        & > input {
          margin: 0;
        }

        & > span {
          min-width: 26px;
        }
      }
    }
  }
`

const InputSwitch = ({ items, value, onChange, variant, ...props }) => {
  return variant === "routes-v2" ? (
    <InputRadios
      items={items}
      value={value}
      onChange={onChange}
      variant={variant}
      {...props}
    />
  ) : (
    <StyledInputRadios
      items={items}
      value={value}
      onChange={onChange}
      variant={variant}
      {...props}
    />
  )
}

InputSwitch.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(["routes-v2", "green2"]),
}

export default InputSwitch
