import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import InputRadio from "@src/components/input-radio"
const HorizontalRadios = css`
  display: flex;
  margin: 0 -7px;
  & > * {
    margin: 0 7px;
  }
  & label {
    white-space: nowrap;
  }
  @media only screen and (${props => props.theme.screen.small.min}) {
    margin: 0 -12px;
    & > * {
      margin: 0 12px;
    }
  }
`
const InputListWrapper = styled.div`
  ${props => (props.horizontal ? HorizontalRadios : "")}
`
const InputWrapper = styled.div``
const InputRadios = ({
  className,
  id,
  name,
  value: defaultValue,
  items,
  onChange,
  variant,
  horizontal,
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue, setValue])

  const updateValue = value => {
    setValue(value)
    if (onChange) {
      onChange(value)
    }
  }
  const handleChange = event => {
    const isChecked = event.target && event.target.checked
    if (isChecked) {
      updateValue(event.target.value)
    }
  }

  const Radios =
    items &&
    items.map((item, index) => {
      return (
        <InputWrapper key={`${item.value}${index}`}>
          <InputRadio
            id={`${id}-${index}`}
            name={name}
            label={item.text}
            value={item.value}
            checked={!!(value && value === item.value)}
            onChange={handleChange}
            variant={variant}
          />
        </InputWrapper>
      )
    })
  return (
    <InputListWrapper className={className} horizontal={horizontal}>
      {Radios}
    </InputListWrapper>
  )
}
InputRadios.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(["blue", "yellow", "green2", "routes-v2"]),
  horizontal: PropTypes.bool,
}
export default InputRadios
