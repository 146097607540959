import { useStaticQuery, graphql } from "gatsby"

export const useRouteActivityTypes = () => {
  const {
    activityTypes: { nodes: activityTypes },
  } = useStaticQuery(graphql`
    query ActivitiesQuery {
      activityTypes: allWpActivityType {
        nodes {
          id: databaseId
          name
          nameSport
          slug
          uri
        }
      }
    }
  `)

  return activityTypes
}
