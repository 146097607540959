/*
Exports:
  <ViewportProvider>
    - listens for Window resize events and provides { width, height } values to its context

  <ViewportContext>
    - React Context with { width, height } values provided by ViewportProvider

  useViewport()
    - hook to read width, height values from a VieportProvider's ViewportContext

Orignal Source:

  https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/

  Modified to support Gatsby's static rendering by checking for window object using browser-monads

Usage notes and examples:

  * Always preferable to solve with CSS, the JS window-resize listener should be used sparingly
  
  * All <ViewportContext.Consumer> must be nested under <ViewportProvider>

  * Add <ViewportProvider> to Pages that need it

  // Example Page with ViewportProvider:
  // - any components nested under ViewportProvider can useViewport() hook
  const Page = () => {
    return (
      <PrimaryLayout>
        <ViewportProvider>
          <ExampleContent />
        </ViewportProvider>
      </PrimaryLayout>
    )
  }
    
  // Example Component using hook:
  const ExampleContent = () => {
    const { width, height } = useViewport()
    return (
      <div>
        [{width} x {height}]
      </div>
    )
  }

  // Example Page with ViewportContext.Consumer
  // - Page component can use width, height in its rendering
  const Page = () => {
    return (
      <PrimaryLayout>
        <ViewportProvider>
          <ViewportContext.Consumer>{
          ({width, height}) => (
            <div>
              [{width} x {height}]
            </div>
          )}</ViewportContext.Consumer>
        </ViewportProvider>
      </PrimaryLayout>
    )
  }
*/
import React from "react"
import PropTypes from "prop-types"
import { window, exists } from "browser-monads"
import { debounce } from "debounce"

/* ViewportContext */
export const ViewportContext = React.createContext({})

/* ViewportProvider */
export const ViewportProvider = ({ children }) => {
  const [width, setWidth] = React.useState(exists(window) && window.innerWidth)
  const [height, setHeight] = React.useState(
    exists(window) && window.innerHeight
  )

  const handleWindowResize = debounce(() => {
    setWidth(exists(window) && window.innerWidth)
    setHeight(exists(window) && window.innerHeight)
  }, 100)

  React.useEffect(() => {
    if (exists(window)) {
      window.addEventListener("resize", handleWindowResize)
      return () => window.removeEventListener("resize", handleWindowResize)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ViewportContext.Provider value={{ width, height }}>
      {children}
    </ViewportContext.Provider>
  )
}
ViewportProvider.propTypes = {
  children: PropTypes.node,
}

/* useViewport() hook - be sure to use nested under a ViewportProvider */
export const useViewport = () => {
  const { width, height } = React.useContext(ViewportContext)
  return { width, height }
}
