import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import Button from "@src/components/core-button"
import Link from "@src/components/core-link"
import InputWrapper from "@src/components/input-wrapper"
import InputCheckboxesTree from "@src/components/input-checkboxes-tree"
import InputRadios from "@src/components/input-radios"
import { MAP_ATTRIBUTION_DATA } from "@src/utils/constants/map-attribution"
import { BASE_PATH_MAP } from "@src/utils/constants"
import { ROUTE_SORTING_OPTIONS } from "@src/utils/constants/route-list-options"
import InputSlider from "@src/components/input-slider"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"
import InputUnitsSwitch from "@src/components/input-units-switch"
import { LocaleContext } from "@src/context/locale-context"
import Distance from "@src/components/core-value-local-distance"

const IconWrapper = styled(IconDetailContentTile)`
  display: flex;
  align-items: center;
  justify-content: center;
  & .icon {
    font-size: 16px;
  }
`

const FiltersPanel = styled.div`
  position: sticky;
  left: 0;

  @media only screen and (${props => props.theme.screen.medium.max}) {
    z-index: ${props => props.theme.layers.panels};
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    top: ${props => props.theme.header.large.height}px;

    .reset-button {
      width: 100%;
      padding: 12px 0;
    }

    .clear-filters-button {
      display: none;
    }
  }
`

const StyledFilters = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
  background: #f9fafb;
  width: 100%;

  @media only screen and (${props => props.theme.screen.medium.max}) {
    border-top: 1px solid ${props => props.theme.white};
    background-color: ${props => props.theme.bg1};
    overflow-y: scroll;
    position: fixed;
    left: 0;
    bottom: 0;
    top: auto;
    display: ${props => (props.isOpen ? "block" : "none")};
    z-index: ${props => props.theme.layers.panels};
    .clear-filters-button .show-results-button {
      position: fixed;
      width: 100%;
      height: 40px;
      padding: 0;
    }
    .reset-button {
      display: none;
    }
    ${props => (props.isOpen ? "padding: 19px 24px;" : undefined)}
    width: 300px;
    overflow-x: hidden;
    ${props =>
      props.isOpen
        ? css`
            height: calc(100vh - ${props.theme.header.medium.height}px);
          `
        : ""}
    @media only screen and (${props => props.theme.header.small.max}) {
      ${props =>
        props.isOpen
          ? css`
              height: calc(100vh - ${props.theme.header.small.height}px);
            `
          : ""}
    }
    @media only screen and (${props => props.theme.header.tablet.max}) {
      right: 0;
      width: auto;
      ${props =>
        props.isOpen
          ? css`
              height: calc(100vh - ${props.theme.header.tablet.height}px);
            `
          : ""}
    }
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    top: ${props => props.theme.header.large.height}px;

    .reset-button {
      width: 100%;
      padding: 12px 0;
    }

    .clear-filters-button {
      display: none;
    }
  }
`

const StyledButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`

const RouteFilters = styled.div`
  & > .field-wrapper.field-collapsed:last-child {
    margin-bottom: 0;
  }

  @media only screen and (${props => props.theme.screen.medium.min}) {
    .sort-by-wrapper {
      gap: 6px;
      flex-direction: column;
      margin-top: 0;
    }
  }
`

const CloseButtonWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 100%;
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    display: none;
  }
`

const MapImageWrapper = styled(ImageWrapper)`
  border-radius: 8px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    border-radius: 0;
    margin: 0;
  }
`

const HeaderMap = styled.div`
  position: relative;
  margin-bottom: 30px;

  & ${MapImageWrapper} {
    height: 134px;
    border-radius: 8px;
  }

  @media only screen and (${props => props.theme.screen.medium.max}) {
    display: none;
  }
`

const MapButton = styled(Button)`
  display: none;

  @media only screen and (${props => props.theme.screen.medium.min}) {
    display: inline-block;
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
`

const AttributionUL = styled.ul`
  list-style-type: none;
  font-size: 8px;
  position: absolute;
  right: 20px;
  bottom: 3px;

  & > li {
    display: inline;
    float: left;
  }
  & li + li::before {
    content: " | ";
    white-space: pre;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: inline-block;
    position: absolute;
    right: 10px;
    bottom: 4px;
  }
`

const LengthSliderLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledInputWrapper = styled(InputWrapper)`
  width: 100%;
`

const RouteFiltersPanel = ({
  map,
  mapPath,
  gps,
  sorting,
  handleSortingChange,
  routeActivityTypes,
  routeRegionsTree,
  filters,
  handleRegionsChange,
  handleActivitiesChange,
  handleTimeChange,
  handleDistanceChange,
  handleDifficultyChange,
  handleRatingChange,
  resetFilters,
  routesLoading,
  showFiltersMore,
  updateShowFiltersMore,
  chatShowing,
  handleScroll,
  distanceUnit,
}) => {
  return (
    <FiltersPanel>
      <StyledFilters isOpen={showFiltersMore}>
        <HeaderMap>
          <Link
            to={`${BASE_PATH_MAP}${mapPath ? `${mapPath}/` : ""}`}
            gps={gps}
            rel="nofollow"
          >
            <Image
              artDirection={{ mobile: "100vw", small: "40vw" }}
              wrapper={MapImageWrapper}
              center
              {...map}
            />
          </Link>
          <MapButton
            to={`${BASE_PATH_MAP}${mapPath ? `${mapPath}/` : ""}`}
            gps={gps}
            rel="nofollow"
            size="small"
            variant="mapV2"
            color="white-black"
          >
            See Map
          </MapButton>
          <AttributionUL>
            {MAP_ATTRIBUTION_DATA.map(data => {
              const { name, uri, text, copyright } = data
              return (
                <li key={name}>
                  {`${name}: ${copyright}`}
                  <Link to={uri}>{text}</Link>
                </li>
              )
            })}
          </AttributionUL>
        </HeaderMap>

        <CloseButtonWrapper>
          <Button
            variant="close"
            color="green"
            size="medium"
            onClick={() => {
              updateShowFiltersMore(false)
            }}
          >
            <IconWrapper key="Close" glyph="close" size="close-small" />
          </Button>
        </CloseButtonWrapper>

        <RouteFilters>
          <StyledInputWrapper
            forId="sort-by"
            label="Sort"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <InputRadios
              id="sort-by"
              name="sort-by"
              items={ROUTE_SORTING_OPTIONS}
              value={sorting}
              onChange={handleSortingChange}
              variant="routes-v2"
            />
          </StyledInputWrapper>

          <StyledInputWrapper
            forId="measurement"
            label="Measurement"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <LocaleContext.Consumer>
              {({ units, setPreferredUnits }) => (
                <InputUnitsSwitch
                  id="route-measurement-units"
                  name="route-measurement-units"
                  value={units}
                  onChange={setPreferredUnits}
                  labelType="long"
                  variant="routes-v2"
                />
              )}
            </LocaleContext.Consumer>
          </StyledInputWrapper>

          <StyledInputWrapper
            forId="regions"
            label="Regions"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <InputCheckboxesTree
              id="regions"
              name="regions"
              items={routeRegionsTree}
              getItemText={item => item.name}
              getItemValue={item => item.id}
              getItemChildren={item => item.items}
              onChange={handleRegionsChange}
              value={filters.regions}
            />
          </StyledInputWrapper>

          <StyledInputWrapper
            forId="activityTypes"
            label="Activity type"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <InputCheckboxesTree
              id="activityTypes"
              name="activityTypes"
              items={routeActivityTypes}
              getItemText={item => item.name}
              getItemValue={item => item.id}
              getItemChildren={item => item.items}
              value={filters.activities}
              onChange={handleActivitiesChange}
            />
          </StyledInputWrapper>

          <StyledInputWrapper
            forId="duration"
            label="Duration"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <InputSlider
              className="duration-slider"
              id="duration"
              name="duration"
              label="Difficulty Input Slider"
              min={0}
              max={144}
              step={1}
              range
              tooltipDisabled
              onChange={value => handleTimeChange(value)}
              value={[
                filters.time && filters.time[0] ? filters.time[0] : 0,
                filters.time && filters.time[1] ? filters.time[1] : 144,
              ]}
              renderValue={value => value}
            />
            <LengthSliderLabel>
              {filters.time && filters.time[0] ? (
                <p>{filters.time[0]} hours</p>
              ) : (
                <p>0 hours</p>
              )}
              {filters.time && filters.time[1] ? (
                <p>{filters.time[1]} hours</p>
              ) : (
                <p>144 hours</p>
              )}
            </LengthSliderLabel>
          </StyledInputWrapper>

          <StyledInputWrapper
            forId="distance"
            label="Length"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <InputSlider
              className="distance-slider"
              id="distance"
              name="distance"
              label="Distance Input Slider"
              min={0}
              max={85}
              step={1}
              range
              tooltipDisabled
              onChange={value => handleDistanceChange(value)}
              value={[
                filters.distance && filters.distance[0]
                  ? filters.distance[0]
                  : 0,
                filters.distance && filters.distance[1]
                  ? filters.distance[1]
                  : 85,
              ]}
              renderValue={value => value}
            />

            <LengthSliderLabel>
              <Distance
                value={
                  filters.distance && filters.distance[0]
                    ? filters.distance[0]
                    : 0
                }
                unit={distanceUnit}
                decimals={0}
              />

              <Distance
                value={
                  filters.distance && filters.distance[1]
                    ? filters.distance[1]
                    : 85
                }
                unit={distanceUnit}
                decimals={0}
              />
            </LengthSliderLabel>
          </StyledInputWrapper>

          <StyledInputWrapper
            forId="difficulty"
            label="Difficulty"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <InputSlider
              className="difficulty-slider"
              id="difficulty"
              name="difficulty"
              label="Difficulty Input Slider"
              min={0}
              max={5}
              step={1}
              range
              tooltipDisabled
              onChange={value => handleDifficultyChange(value)}
              value={[
                filters.difficulty && filters.difficulty[0]
                  ? filters.difficulty[0]
                  : 0,
                filters.difficulty && filters.difficulty[1]
                  ? filters.difficulty[1]
                  : 5,
              ]}
              renderValue={value => value}
            />
            <LengthSliderLabel>
              {filters.difficulty && filters.difficulty[0] ? (
                <p>{filters.difficulty[0]}</p>
              ) : (
                <p>0</p>
              )}
              {filters.difficulty && filters.difficulty[1] ? (
                <p>{filters.difficulty[1]}</p>
              ) : (
                <p>5</p>
              )}
            </LengthSliderLabel>
          </StyledInputWrapper>

          <StyledInputWrapper
            forId="rating"
            label="Rating"
            collapsible
            bottomBorder
            variant="routes-v2"
          >
            <InputSlider
              className="rating-slider"
              id="rating"
              name="rating"
              label="Rating Input Slider"
              min={0}
              max={10}
              step={1}
              range
              tooltipDisabled
              onChange={value => handleRatingChange(value)}
              value={[
                filters.rating && filters.rating[0] ? filters.rating[0] : 0,
                filters.rating && filters.rating[1] ? filters.rating[1] : 10,
              ]}
              renderValue={value => value}
            />
            <LengthSliderLabel>
              {filters.rating && filters.rating[0] ? (
                <p>{filters.rating[0]}</p>
              ) : (
                <p>0</p>
              )}
              {filters.rating && filters.rating[1] ? (
                <p>{filters.rating[1]}</p>
              ) : (
                <p>10</p>
              )}
            </LengthSliderLabel>
          </StyledInputWrapper>
        </RouteFilters>

        <StyledButtonsContainer>
          {showFiltersMore && (
            <Button
              className="show-results-button"
              color="green"
              onClick={() => {
                updateShowFiltersMore(false)
                handleScroll()
              }}
              $chatShowing={chatShowing}
            >
              {routesLoading ? "Searching for routes ..." : "Show Results"}
            </Button>
          )}

          <Button
            className="reset-button"
            onClick={resetFilters}
            color="routes-v2"
          >
            Reset Filters
          </Button>

          <Button
            className="clear-filters-button"
            onClick={resetFilters}
            color="dark-blue-outline"
          >
            Clear Filters
          </Button>
        </StyledButtonsContainer>
      </StyledFilters>
    </FiltersPanel>
  )
}

RouteFiltersPanel.propTypes = {
  mapPath: PropTypes.string,
  title: PropTypes.string,
  map: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  gps: PropTypes.oneOfType([
    PropTypes.shape({
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({ lat: PropTypes.number, lon: PropTypes.number })
    ),
  ]),
  sorting: PropTypes.string,
  handleSortingChange: PropTypes.func,
  routeRegionsTree: PropTypes.array,
  routeActivityTypes: PropTypes.array,
  filters: PropTypes.shape({
    regions: PropTypes.array,
    activities: PropTypes.array,
    time: PropTypes.array,
    distance: PropTypes.array,
    difficulty: PropTypes.array,
    rating: PropTypes.array,
    distanceUnit: PropTypes.string,
  }),
  handleRegionsChange: PropTypes.func,
  handleActivitiesChange: PropTypes.func,
  handleTimeChange: PropTypes.func,
  handleDistanceChange: PropTypes.func,
  handleDifficultyChange: PropTypes.func,
  handleRatingChange: PropTypes.func,
  resetFilters: PropTypes.func,
  routesLoading: PropTypes.bool,
  showFiltersMore: PropTypes.bool,
  updateShowFiltersMore: PropTypes.func,
  chatShowing: PropTypes.number,
  handleScroll: PropTypes.func,
  distanceUnit: PropTypes.string,
}

export default RouteFiltersPanel
