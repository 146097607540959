import React from "react"
import styled from "styled-components"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import Link from "@src/components/core-link"

const StoreBadgeWrappers = styled(ImageWrapper)`
  width: 130px;
  // Specifically needed to align and maintain same sizes for app store and google play store download badges
  // https://stackoverflow.com/questions/47590571/increase-margin-on-app-store-badge-to-match-size-of-google-play-store-badge
  & > .lazyload-wrapper > img.app-store-download {
    width: 88%;
    // margin: 6%;
    margin-top: 6px;
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    width: 150px;
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    width: 200px;
    margin-top: 8px;
  }
`

const AppDownloadLinks = styled.div`
  display: flex;
  flex-direction: row;
  left: 10px;
  bottom: 0;
  z-index: 3;
  margin-top: 16px;
`

const AppCTA = styled.div`
  position: relative;
  background: #26383b;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 720px;

  & h3 {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: system-ui;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.5px;
    margin: 0px;
    padding-right: 20px;
  }

  & > h2,
  & > p,
  & > button {
    left: 16px;
    right: 40%;
    z-index: 1;
  }
  & > h2 {
    top: 0;
    font-weight: 700;
  }
  & > button {
    bottom: 10px;
    left: 10px;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    height: 610px;
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    & h3 {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: system-ui;
      font-size: 42px;
      font-style: normal;
      font-weight: 900;
      line-height: 64px; /* 152.381% */
      letter-spacing: 0.5px;
      margin: 0px;
    }
    & > h2,
    & > p,
    & > button {
      left: 16px;
      right: 40%;
      z-index: 1;
    }
    & > h2 {
      top: 0;
      font-weight: 700;
    }
    & > button {
      bottom: 10px;
      left: 10px;
    }
    & > h2 {
      right: 35%;
    }
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    height: 550px;
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    & > h2 {
      right: 50%;
    }
    & h3 {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: system-ui;
      font-size: 42px;
      font-style: normal;
      font-weight: 900;
      line-height: 64px; /* 152.381% */
      letter-spacing: 0.5px;
      margin: 0px;
    }
    & > h2,
    & > p,
    & > button {
      left: 16px;
      right: 40%;
      z-index: 1;
    }
    & > h2 {
      top: 0;
      font-weight: 700;
    }
    & > button {
      bottom: 10px;
      left: 10px;
    }
  }
  @media only screen and (${props => props.theme.screen.desktop.min}) {
    height: 509px;
    display: grid;
    grid-template-columns: 1.1fr 0.9fr;
    & > h2 {
      right: 55%;
    }
    & h3 {
      color: #fff;
      font-feature-settings: "clig" off, "liga" off;
      font-family: system-ui;
      font-size: 42px;
      font-style: normal;
      font-weight: 900;
      line-height: 64px; /* 152.381% */
      letter-spacing: 0.5px;
      margin: 0px;
    }
    & > h2,
    & > p,
    & > button {
      left: 16px;
      right: 40%;
      z-index: 1;
    }
    & > h2 {
      top: 0;
      font-weight: 700;
    }
    & > button {
      bottom: 10px;
      left: 10px;
    }
  }
`
const Badge = styled.span`
  display: inline-flex;
  padding: 5px 10px;
  align-items: center;
  gap: 1px;
  border-radius: 6px;
  background: #41c689;
  width: fit-content;
  margin-top: 30px;
  margin-bottom: 24px;

  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: system-ui;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */

  @media only screen and (${props => props.theme.screen.small.min}) {
    margin-top: 91px;
  }
`

const TextPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;

  @media only screen and (${props => props.theme.screen.medium.min}) {
    padding-left: 115px;
    margin: 20px;
  }
`
const DownloadText = styled.div`
  color: #41c689;
  font-family: system-ui;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.09px;
  margin-top: 16px;
`

const ImagePanel = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%; /* set the height to 100% */
`

const ScreenshotImage = styled(Image)`
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -62%);
  margin: 0;
`

const CTAGetAppTrailDetails = () => {
  const appStoreLink = process.env.GATSBY_10A_APP_STORE_LINK
  const playStoreLink = process.env.GATSBY_10A_PLAY_STORE_LINK
  return (
    <AppCTA>
      <TextPanel>
        <Badge>10Adventures Trail App</Badge>
        <h3>
          Take your trails online! Keep track of your route along the way.
        </h3>
        <DownloadText>Download for free today.</DownloadText>
        <AppDownloadLinks>
          <Link to={appStoreLink}>
            <Image
              className="app-store-download"
              src="/icons/app-store-badge.svg"
              alt="App store icon"
              wrapper={StoreBadgeWrappers}
              width={115}
              height={40}
            />
          </Link>
          <Link to={playStoreLink}>
            <Image
              src="/icons/google-play-badge.png"
              alt="Play store icon"
              wrapper={StoreBadgeWrappers}
              width={130}
              height={50}
            />
          </Link>
        </AppDownloadLinks>
      </TextPanel>
      <ImagePanel>
        <ScreenshotImage
          placeholder
          src="/images/screenshot-mobile.jpg"
          alt="10adventures-mobile-screenshot"
          height={603}
          width={312}
        />
      </ImagePanel>
    </AppCTA>
  )
}

export default CTAGetAppTrailDetails
