import { DIFFICULTY_RATINGS } from "./difficulty-ratings"

export const ROUTE_DURATION_OPTIONS = [
  {
    text: "0.5 hours",
    value: { durationMin: 0, durationMax: 0.5 },
  },
  {
    text: "1-2 hours",
    value: { durationMin: 1, durationMax: 2 },
  },
  {
    text: "3-5 hours",
    value: { durationMin: 3, durationMax: 5 },
  },
  {
    text: "6-9 hours",
    value: { durationMin: 6, durationMax: 9 },
  },
  {
    text: "10+ hours",
    value: { durationMin: 10, durationMax: 30 },
  },
]

export const ROUTE_RATING_OPTIONS = [
  {
    text: "10",
    value: { durationMin: 10, durationMax: 10 },
  },
  {
    text: "9",
    value: { durationMin: 9, durationMax: 9 },
  },
  {
    text: "8",
    value: { durationMin: 8, durationMax: 8 },
  },
  {
    text: "7",
    value: { durationMin: 7, durationMax: 7 },
  },
  {
    text: "0+",
    value: { durationMin: 0, durationMax: 6 },
  },
]

export const ROUTE_DIFFICULTY_OPTIONS = DIFFICULTY_RATINGS.map(
  ({ title, ...rating }) => ({
    text: title,
    ...rating,
  })
)

export const ROUTE_SORTING_OPTIONS = [
  {
    text: "Most Popular",
    value: "default",
  },
  {
    text: "Highest Rated",
    value: "rating_DESC",
  },
  {
    text: "Shortest to Longest",
    value: "distance_ASC",
  },
  {
    text: "Longest to Shortest",
    value: "distance_DESC",
  },
  // {
  //   text: "Alphabetically (A to Z)",
  //   value: "Alpha_ASC",
  // },
  // {
  //   text: "Alphabetically (Z to A)",
  //   value: "Alpha_DESC",
  // },

]
