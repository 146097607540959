import React, { useState, useContext } from "react"
import PropTypes from "prop-types"
import GTM from "@src/services/gtm"
import PrimaryLayout from "@src/layouts/primary"
import PageHelmet, { PageSeoPropTypes } from "@src/components/page-helmet"
import { normalizeTitle, generateMeta, paginateUrl } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import HtmlContent from "@src/components/core-value-html"
import { RouteGuideDetailTilePropTypes } from "@src/components/content-route-guide-detail-tile"
import { ImagePropTypes } from "@src/components/core-image"
import { SITE_DOMAIN } from "@src/utils/constants"
import generateBreadcrumbList from "@src/utils/structured-data/generateBreadcrumbList"
import { useRouteBreadcrumbs } from "@src/hooks/useRouteBreadcrumbs"
import HeaderRegion from "@src/components/page-header-region-new"
import styled, { css } from "styled-components"
import Image from "@src/components/core-image"
import ImageWrapper from "@src/components/core-image-wrapper"
import ResponsiveColumns from "@src/components/container-responsive-columns"
import BreadcrumbsV2 from "@src/components/nav-content-breadcrumbs-v2"
import IconDetailContentTile from "@src/components/core-icon-detail-tile"
import DifficultyRating from "@src/components/core-value-difficulty-rating"
import RouteFiltersPanel from "@src/components/content-route-filters-panel"
import NavPaginate from "@src/components/nav-content-paginate"
import DisplayFilterResultCount from "@src/components/content-tour-filter-results-count"
import Spinner from "@src/components/core-spinner"
import Link from "@src/components/core-link"
import Button from "@src/components/core-button"
import { LayerContext } from "@src/context/layer-context"
import { exists, window, document } from "browser-monads"
import { MAP_ATTRIBUTION_DATA } from "@src/utils/constants/map-attribution"
import { BASE_PATH_MAP } from "@src/utils/constants"
import ReadMoreText from "@src/components/core-value-read-more-text"
import Distance from "@src/components/core-value-local-distance"
import TravelAdvisorConnect from "@src/components/travel-advisor-connect"
import TrailAppCTA from "@src/components/cta-get-app-trail-details"
// import { TrailsLinksToTours } from "@src/components/trails-links-to-tours"

const RouteLayoutStyle = css`
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 14px;
  color: #1f1f23;
  & h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
  }
`

const PageStyling = styled.div`
  background: #f9fafb;
  @media only screen and (${props => props.theme.header.medium.max}) {
    padding-bottom: 86px;
  }
`

const DescriptionWrapper = styled.div`
  ${RouteLayoutStyle}
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000;
  & h2 {
    font-size: 46px;
    font-weight: 900;
    line-height: 64px;
  }
  & p {
    font-weight: 400;
  }

  @media only screen and (${props => props.theme.header.medium.max}) {
  & h2 {
    font-size: 32px;
  }

`

const RouteSummary = styled(HtmlContent)`
  ${RouteLayoutStyle}
  // Reference: https://css-tricks.com/almanac/properties/l/line-clamp/
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  line-height: 21px;
  text-overflow: ellipsis;

  @media only screen and (${props => props.theme.header.tablet.max}) {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    font-size: 14px;
  }
`

const RegionActivityDescription = styled(HtmlContent)`
  p,
  li {
    color: #1f1f23;
    font-size: 14px;
    line-height: 21px;
  }
`

const StyledBreadcrumbs = styled(BreadcrumbsV2)`
  margin-top: 20px;
`

const RoutesSpinner = styled(Spinner)`
  position: sticky;
  top: calc(30vh + ${props => props.theme.header.tablet.height}px);
  @media only screen and (${props => props.theme.screen.small.min}) {
    top: calc(30vh + ${props => props.theme.header.small.height}px);
  }
  @media only screen and (${props => props.theme.screen.medium.min}) {
    top: calc(30vh + ${props => props.theme.header.medium.height}px);
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    top: calc(30vh + ${props => props.theme.header.large.height}px);
  }
`

const RoutesSpinnerWrapper = styled.div`
  position: absolute;
  z-index: 10;
  height: 100%;
  left: 0;
  right: 0;
  padding: 0 0 100px;
`

const CardSection = styled.div`
  position: relative;
  ${props =>
    props.variant === "vertical"
      ? css`
          @media only screen and (${props => props.theme.screen.medium.min}) {
            justify-content: flex-start;
          }
        `
      : undefined}
`

const RouteListWrapper = styled.section`
  position: relative;
  min-width: 300px;
  &.loading {
    & > nav {
      position: relative;
      z-index: 20;
    }
    & > ${CardSection}::after {
        content: "";
        position: absolute;
        z-index: 5;
        background-color: ${props => props.theme.white};
        opacity: 0.9;
      }
    }
  }
  & > p {
    ${RouteLayoutStyle}
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #667085;
    margin-top: 12px;
  }

`

const Card = styled(Link)`
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 15px;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;
  & .card-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
  }

  @media only screen and (${props => props.theme.header.tablet.max}) {
    flex-direction: column;

    & .card-details {
      padding: 10px;
      width: 100%;
    }
  }
`

const CardImageWrapper = styled(ImageWrapper)`
  border-radius: 8px;
  width: 270px;
  height: 205px;
  flex: 0 0 270px;

  @media only screen and (${props => props.theme.header.tablet.max}) {
    width: 100%;
  }
`

const RouteDetailsTop = styled.div`
${RouteLayoutStyle}
  display: flex;
  align-items: center;
  width: fit-content;
  color: #757575;
  font-size: 14px;
  font-weight: 600;
  & span.route-region {
    text-decoration: underline;
  }
  .styled-dot {
    ${RouteLayoutStyle}
    font-size: 14px;
    color: #4F4F4F;
    margin: 0 10px;
`

const RouteDetailsBottom = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #26383b;

  & > div {
    margin-right: 20px;
  }

  @media only screen and (${props => props.theme.header.tablet.max}) {
    & > div {
      flex: 1 1 50%;
      margin-right: 0;
    }
    margin-right: 0;
  }
`

const RouteDescription = styled.div`
  ${RouteLayoutStyle}
  margin: 10px 0 20px 0;
  h3 {
    font-size: 22px;
  }

  @media only screen and (${props => props.theme.header.tablet.max}) {
    h3 {
      font-size: 18px;
    }
  }
`

const IconWrapper = styled(IconDetailContentTile)`
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 8px 0;
  & .icon {
    font-size: 14px;
  }
`

const NoResultsSection = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  color: #1f1f23;
  font-weight: 700;
`

const DisplayResultCount = styled(DisplayFilterResultCount)`
  display: flex;
  justify-content: end;
`

const bottomButton = css`
  position: fixed;
  bottom: 16px;
  left: 50%;
  width: 300px;
  transform: translateX(-50%);
  font-size: 16px;
  padding: 12px 36px;

  & > .icon {
    display: inline-block;
  }

  @media only screen and (${props => props.theme.screen.mobile.max}) {
    width: ${props => (props.$chatShowing ? "auto" : "200px")};
  }
  @media only screen and (${props => props.theme.screen.large.min}) {
    display: none;
  }
`

const FiltersButtonContainer = styled.div`
  @media only screen and (${props => props.theme.screen.medium.max}) {
    background-color: #ffffff;
    width: 100%;
    height: 86px;
    position: fixed;
    bottom: 0;
    display: ${props => (props.isOpen ? "none" : "block")};
    z-index: ${props => props.theme.layers.floatingUI};
    border-top: 1px solid rgb(223, 223, 223);
    border-bottom: 1px solid rgb(223, 223, 223);
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    display: none;
  }
`

const FiltersButton = styled(Button)`
  ${bottomButton}
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  z-index: ${props => props.theme.layers.floatingUI};
`

const MapImageWrapper = styled(ImageWrapper)`
  border-radius: 8px;
  margin: 0;

  @media only screen and (${props => props.theme.screen.large.min}) {
    display: none;
  }
`

const HeaderMap = styled.div`
  position: relative;
  margin: 30px 0;

  & ${MapImageWrapper} {
    height: 134px;
    border-radius: 8px;
  }

  @media only screen and (${props => props.theme.screen.large.min}) {
    display: none;
  }
`

const MapButton = styled(Button)`
  display: none;

  @media only screen and (${props => props.theme.screen.medium.max}) {
    display: inline-block;
    position: absolute;
    right: 10px;
    bottom: 20px;
  }
`

const AttributionUL = styled.ul`
  list-style-type: none;
  font-size: 8px;
  position: absolute;
  right: 20px;
  bottom: 3px;

  & > li {
    display: inline;
    float: left;
  }
  & li + li::before {
    content: " | ";
    white-space: pre;
  }

  @media only screen and (${props => props.theme.screen.small.min}) {
    display: inline-block;
    position: absolute;
    right: 10px;
    bottom: 4px;
  }
`

const StyledResponsiveColumns = styled(ResponsiveColumns)`
  margin-bottom: 50px;
`

const RouteLayout = ({
  regionId,
  regionName,
  regionSlug,
  regionTypeName,
  regionContinent,
  header,
  regionMap,
  regionGps,
  pageSeo,
  regionActivityTitle,
  regionActivityDescription,
  seoTitle,
  seoDescription,
  activityName,
  activityUri,
  activityTypeNameSport,
  routeGuides,
  regionActivityNameSport,
  activityTypeIds,
  basePath,
  sorting,
  handleSortingChange,
  routeRegionsTree,
  routeActivityTypes,
  filters,
  handleRegionsChange,
  handleActivitiesChange,
  handleTimeChange,
  handleDistanceChange,
  handleDifficultyChange,
  handleRatingChange,
  resetFilters,
  handlePageChange,
  page,
  countOffset,
  postsTotal,
  postsPerPage,
  pageNumber,
  pageCount,
  routesLoading,
  handleScroll,
}) => {
  const [showFiltersMore, setShowFiltersMore] = useState(false)
  const Layers = useContext(LayerContext)

  const updateShowFiltersMore = value => {
    if (showFiltersMore !== value) {
      if (value) {
        Layers.open({ layerId: "route-filters-panel", lock: true })
      } else {
        Layers.close("route-filters-panel")
      }
      setShowFiltersMore(value)
    }
  }
  let chatShowing
  if (exists(window) && exists(document)) {
    chatShowing = document.getElementsByClassName("zsiq_floatmain").length
  }

  const routeGuideDetails = routeGuides.nodes.map(routeGuide => {
    return {
      name: routeGuides && routeGuide.name,
      difficulty: routeGuides && routeGuide.difficulty,
      distance: routeGuides && routeGuide.distance,
      duration: routeGuides && routeGuide.duration,
      elevation: routeGuides && routeGuide.elevation,
      images: routeGuides && routeGuide.images,
      ratings: routeGuides && routeGuide.ratings,
      summary: routeGuides && routeGuide.summary,
      regionName: routeGuides && routeGuide.regionName,
      region: routeGuides && routeGuide.region.name,
      uri: routeGuides && routeGuide.uri,
    }
  })

  const dataRouteGuides = routeGuides.nodes
  const breadcrumbRegions = useRouteBreadcrumbs(regionId)
  const gtmPageDetails = {}

  const regionDetails = { regionTypeName, parent: regionContinent }
  const breadcrumbs = [
    {
      text: activityName,
      link: `${activityUri}`,
    },
  ]
  if (breadcrumbRegions && breadcrumbRegions.length) {
    breadcrumbRegions.forEach(region =>
      breadcrumbs.push({
        text: region.text,
        link: `${activityUri}${region.slug}/`,
      })
    )
  }
  gtmPageDetails["page-country"] = regionName

  GTM.dataLayerPushPageInfo({
    template: "activity-region-country",
    ...gtmPageDetails,
  })

  // Structured Data
  const rawBreadcrumbStructuredData = breadcrumbs.map((breadcrumb, index) => {
    return {
      name: breadcrumb.text,
      url: `${SITE_DOMAIN}${breadcrumb.link}`,
      position: index + 1,
    }
  })

  // Get summary of results for display
  const getResultsSummary = page => {
    let resultCount = page.postsPerPage
    let totalResults = page.postsTotal
    let countOffset = page.countOffset

    let showingResults = resultCount + countOffset

    if (totalResults === 0) {
      return " "
    }
    if (totalResults === 1) {
      return "1 route"
    }
    return `${countOffset + 1}-${
      totalResults > showingResults ? showingResults : totalResults
    } of ${totalResults} routes`
  }

  const distanceUnit = routeGuideDetails[0]?.distance?.unit || "km"

  return (
    <PageStyling>
      <PrimaryLayout showSeo={false}>
        <PageHelmet
          title={normalizeTitle(seoTitle)}
          meta={generateMeta(`${activityUri}${regionSlug}/`, {
            ...pageSeo,
            metaRobotsNoindex: "index",
            metaDesc: seoDescription,
          })}
          structuredData={generateBreadcrumbList(rawBreadcrumbStructuredData)}
        />

        <HeaderRegion
          title={regionActivityTitle}
          breadcrumbs={breadcrumbs}
          image={header}
          regionSlug={regionSlug}
          content={regionActivityDescription}
          regionDetails={regionDetails}
          activityTypeNameSport={activityTypeNameSport}
          regionActivityNameSport={regionActivityNameSport}
        />

        <ResponsiveSection>
          <StyledBreadcrumbs breadcrumbItems={breadcrumbs} />
          <DescriptionWrapper>
            <ReadMoreText>
              <RegionActivityDescription html={regionActivityDescription} />
            </ReadMoreText>
          </DescriptionWrapper>
          <HeaderMap>
            <Link
              to={`${BASE_PATH_MAP}${regionSlug ? `${regionSlug}/` : ""}`}
              gps={regionGps}
              rel="nofollow"
            >
              <Image
                artDirection={{ mobile: "100vw", small: "40vw" }}
                wrapper={MapImageWrapper}
                center
                {...regionMap}
              />
            </Link>
            <MapButton
              to={`${BASE_PATH_MAP}${regionSlug ? `${regionSlug}/` : ""}`}
              gps={regionGps}
              size="small"
              variant="mapV2"
              color="white-black"
            >
              See Map
            </MapButton>
            <AttributionUL>
              {MAP_ATTRIBUTION_DATA.map(data => {
                const { name, uri, text, copyright } = data
                return (
                  <li key={name}>
                    {`${name}: ${copyright}`}
                    <Link to={uri}>{text}</Link>
                  </li>
                )
              })}
            </AttributionUL>
          </HeaderMap>
          <DisplayResultCount
            resultCount={page.postsPerPage}
            countOffset={page.countOffset}
            totalResults={page.postsTotal}
            variant={"routes"}
            isPanel={true}
            className="result-count"
          />
        </ResponsiveSection>

        <ResponsiveSection>
          <FiltersButtonContainer isOpen={showFiltersMore}>
            <FiltersButton
              color="routes-v2"
              onClick={() => {
                updateShowFiltersMore(!showFiltersMore)
              }}
              $hide={!!showFiltersMore}
              $chatShowing={chatShowing}
            >
              <IconWrapper key="Sort" glyph="v2-filter" />
              Filter & Sort
            </FiltersButton>
          </FiltersButtonContainer>
        </ResponsiveSection>

        <StyledResponsiveColumns
          columns={{
            medium: {
              sideWidth: 0,
              spacing: 0,
            },
            large: {
              sideWidth: 200,
              spacing: 20,
            },
            desktop: {
              sideWidth: 285,
              spacing: 20,
            },
          }}
        >
          <RouteFiltersPanel
            title={regionActivityTitle}
            image={header}
            map={regionMap}
            regionSlug={regionSlug}
            content={regionActivityDescription}
            regionDetails={regionDetails}
            regionId={regionId}
            activityId={activityTypeIds}
            dataRouteGuides={dataRouteGuides}
            pageNumber={pageNumber}
            pageCount={pageCount}
            postsPerPage={postsPerPage}
            postsTotal={postsTotal}
            basePath={basePath}
            sorting={sorting}
            handleSortingChange={handleSortingChange}
            routeRegionsTree={routeRegionsTree}
            routeActivityTypes={routeActivityTypes}
            filters={filters}
            handleTimeChange={handleTimeChange}
            handleRegionsChange={handleRegionsChange}
            handleActivitiesChange={handleActivitiesChange}
            handleDistanceChange={handleDistanceChange}
            handleDifficultyChange={handleDifficultyChange}
            handleRatingChange={handleRatingChange}
            resetFilters={resetFilters}
            countOffset={countOffset}
            routesLoading={routesLoading}
            showFiltersMore={showFiltersMore}
            updateShowFiltersMore={updateShowFiltersMore}
            chatShowing={chatShowing}
            handleScroll={handleScroll}
            distanceUnit={distanceUnit}
          />

          <RouteListWrapper
            className={routesLoading ? "loading" : ""}
            id="route-container"
          >
            {routesLoading && (
              <RoutesSpinnerWrapper>
                <RoutesSpinner align="center" />
              </RoutesSpinnerWrapper>
            )}
            {routeGuideDetails.length > 0 ? (
              <CardSection>
                {routeGuideDetails.map((routeGuideDetail, index) => {
                  return (
                    <Card
                      key={index}
                      to={routeGuideDetail.uri}
                      variant="simple"
                    >
                      <Image
                        placeholder
                        wrapper={CardImageWrapper}
                        src={routeGuideDetail.images.detailTile.src}
                        alt={routeGuideDetail.images.detailTile.alt}
                      />
                      <div className="card-details">
                        <RouteDetailsTop>
                          <IconWrapper
                            key="Rating"
                            glyph="v2-solid-star"
                            color="primary"
                          >
                            {routeGuideDetail.ratings.overall
                              ? `${routeGuideDetail.ratings.overall} overall rating`
                              : "No Ratings"}
                          </IconWrapper>
                          <span className="styled-dot">•</span>
                          <IconWrapper
                            key="Region"
                            glyph="v2-solid-marker"
                            color="routes-teal"
                          >
                            <span className="route-region">
                              {routeGuideDetail.region.split(",")[0]}
                            </span>
                          </IconWrapper>
                        </RouteDetailsTop>
                        <RouteDescription>
                          <h3>{routeGuideDetail.name}</h3>
                          <RouteSummary html={routeGuideDetail.summary} />
                        </RouteDescription>
                        <RouteDetailsBottom>
                          <IconWrapper
                            key="Difficulty"
                            glyph="v2-thick-Fire"
                            color="secondary"
                          >
                            {routeGuideDetail.difficulty ? (
                              <DifficultyRating
                                ratingNumber={Number(
                                  routeGuideDetail.difficulty
                                )}
                              />
                            ) : (
                              "Not Available"
                            )}
                          </IconWrapper>
                          <IconWrapper
                            key="Distance"
                            glyph="v2-backpack"
                            color="secondary"
                          >
                            <Distance
                              value={routeGuideDetail.distance.value}
                              unit={routeGuideDetail.distance.unit}
                              decimals={1}
                            />
                          </IconWrapper>
                          <IconWrapper
                            key="Elevation"
                            glyph="v2-mountains"
                            color="secondary"
                          >
                            <Distance
                              value={routeGuideDetail.elevation.value}
                              unit={routeGuideDetail.elevation.unit}
                              decimals={0}
                            />
                          </IconWrapper>
                          <IconWrapper
                            key="Time"
                            glyph="v2-stopwatch"
                            color="secondary"
                          >
                            {routeGuideDetail.duration ? (
                              <span>{routeGuideDetail.duration}</span>
                            ) : (
                              "Not Available"
                            )}
                          </IconWrapper>
                        </RouteDetailsBottom>
                      </div>
                    </Card>
                  )
                })}
              </CardSection>
            ) : (
              <NoResultsSection>
                <p>The search returned no results.</p>
              </NoResultsSection>
            )}

            {routeGuideDetails.length === 0 ? null : routeGuides ===
              dataRouteGuides ? (
              <NavPaginate
                current={page.pageNumber}
                total={page.pageCount}
                getLinkProps={pageNumber => ({
                  to: paginateUrl(basePath, pageNumber),
                })}
                variant="routes"
              />
            ) : (
              <NavPaginate
                current={page.pageNumber}
                total={page.pageCount}
                setPage={handlePageChange}
                variant="routes"
              />
            )}
            <p>{getResultsSummary(page)}</p>
          </RouteListWrapper>
        </StyledResponsiveColumns>

        <TravelAdvisorConnect title={regionActivityTitle} />
        <TrailAppCTA />
        {/* <TrailsLinksToTours /> */}
      </PrimaryLayout>
    </PageStyling>
  )
}
RouteLayout.propTypes = {
  regionId: PropTypes.number,
  regionName: PropTypes.string,
  regionSlug: PropTypes.string,
  regionTypeName: PropTypes.string,
  regionContinent: PropTypes.string,
  header: PropTypes.shape({ ...ImagePropTypes }),
  regionMap: PropTypes.shape({ ...ImagePropTypes }),
  pageSeo: PropTypes.shape({ ...PageSeoPropTypes }),
  regionActivityTitle: PropTypes.string,
  regionActivityDescription: PropTypes.string,
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  activityName: PropTypes.string,
  activityUri: PropTypes.string,
  activityTypeNameSport: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, uri: PropTypes.string })
  ),
  regionActivityNameSport: PropTypes.string,
  routeGuides: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({ ...RouteGuideDetailTilePropTypes })
    ),
  }),
  activityTypeIds: PropTypes.arrayOf(PropTypes.number),
  basePath: PropTypes.string,
  pageNumber: PropTypes.number,
  pageCount: PropTypes.number,
  postsPerPage: PropTypes.number,
  postsTotal: PropTypes.number,
  sorting: PropTypes.string,
  page: PropTypes.shape({
    pageCount: PropTypes.number,
    countOffset: PropTypes.number,
    postsPerPage: PropTypes.number,
    postsTotal: PropTypes.number,
    pageNumber: PropTypes.number,
  }),
  handleSortingChange: PropTypes.func,
  routeRegionsTree: PropTypes.array,
  routeActivityTypes: PropTypes.array,
  filters: PropTypes.shape({
    regions: PropTypes.array,
    activities: PropTypes.array,
    sortBy: PropTypes.string,
  }),
  handleRegionsChange: PropTypes.func,
  handleActivitiesChange: PropTypes.func,
  handleTimeChange: PropTypes.func,
  handleDistanceChange: PropTypes.func,
  handleDifficultyChange: PropTypes.func,
  handleRatingChange: PropTypes.func,
  resetFilters: PropTypes.func,
  handlePageChange: PropTypes.func,
  countOffset: PropTypes.number,
  routesLoading: PropTypes.bool,
  regionGps: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  handleScroll: PropTypes.func,
}

export default RouteLayout
